<template>
  <div class="content">
    <div class="row">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <div class="row">
            <div class="col-12">
              <h4 slot="header" class="card-title">Adicionar cidade</h4>
            </div>
            <div class="col-5 mt-3">
              <label>Estado</label>
              <el-select class="select-primary" v-model="selectedState" filterable placeholder="Selecione um estado">
                <el-option class="select-primary" v-for="item in states.data" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </div>
            <div class="col-5 mt-3">
              <label>Cidade</label>
              <el-select :class="{'select-primary': !disabledCity}" v-model="selectedCity" filterable placeholder="Selecione uma cidade" :disabled="disabledCity">
                <el-option class="select-primary" v-for="item in cities.data" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </div>
            <div class="col-2 mt-3 d-flex align-items-end">
              <el-button class="btnEnableCity btn new-btn" @click="addCity" :disabled="selectedCity === ''">Habilitar Cidade</el-button>
            </div>
            <div class="col-12 mt-3">
              <h4 slot="header" class="card-title">Cidades Habilitadas</h4>
            </div>
            <div class="col-12 d-flex" v-if="ready">
              <div class="row">
                <div v-for="(item, index) in enabledCities.data" :key="index" class="ml-3">
                  <span class="d-flex align-items-center myTag mb-3">
                    {{ item.name }} - {{ getStateName(item.province_id).name }}
                    <i class="fas fa-times ml-3 d-flex align-items-center justify-content-around" @click="handleDelete(item.name, getStateName(item.province_id).name, item.id)"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { Button } from 'element-ui'
import swal from 'sweetalert2'
export default {
  name: 'index',
  components: {
    [Button.name]: Button
  },
  data () {
    return {
      states: [],
      cities: [],
      selectedState: '',
      selectedCity: '',
      disabledCity: true,
      enabledCities: [],
      ready: false
    }
  },
  async mounted () {
    await this.getEnabledCities()
    this.states = await axios.get('/provinces')
  },
  methods: {
    async getCitiesByState (state) {
      this.selectedCity = ''
      this.cities = await axios.get('/cities?province_id=' + state)
    },
    async getEnabledCities () {
      this.enabledCities = await axios.get('/cities/enabled', {
        headers: {
          Authorization: this.$jwt.getAccessToken()
        }
      })
    },
    async addCity () {
      if (this.selectedCity === '') {
        await swal.fire({
          title: 'Erro!',
          text: 'Ocorreu um erro, tente selecionar a cidade primeiro.',
          denyButtonText: 'Ok',
          showDenyButton: true,
          showConfirmButton: false
        })

        return
      }

      const response = await axios.post('/cities/enabled', {
        ids: [
          this.selectedCity
        ]
      },
      {
        headers: {
          Authorization: this.$jwt.getAccessToken()
        }
      })

      if (response.status === 200) {
        await swal.fire({
          title: 'Adicionado!',
          text: response.data.message,
          showConfirmButton: false,
          timer: 2000
        })

        this.selectedState = ''
        this.selectedCity = ''
        await this.getEnabledCities()
      } else {
        await swal.fire({
          title: 'Erro!',
          text: 'Ocorreu um erro, tente novamente mais tarde.',
          denyButtonText: 'Ok',
          showDenyButton: true,
          showConfirmButton: false
        })
      }
    },
    async handleDelete (city, state, cityId) {
      await swal.fire({
        title: 'Você tem certeza?',
        text: 'Você está prestes a deletar ' + city + ' - ' + state + '.',
        showCancelButton: true,
        confirmButtonText: 'Sim, eu quero deletar!',
        cancelButtonText: 'Cancelar'
      }).then(result => {
        if (result.value) {
          swal.fire({
            title: 'Deletando',
            text: 'Aguarde, estamos deletando ' + city + ' - ' + state + '.',
            showConfirmButton: false,
            timer: 1000,
            onBeforeOpen: () => {
              swal.showLoading()
            }
          })
          this.removeCity(cityId)
        }
      })
    },
    async removeCity (cityId) {
      const response = await axios.delete('/cities/enabled', {
        headers: {
          Authorization: this.$jwt.getAccessToken()
        },
        data: {
          ids: [
            cityId
          ]
        }
      })

      if (response.status === 200) {
        await swal.fire({
          title: 'Removido!',
          text: 'Cidade removida com sucesso.',
          showConfirmButton: false,
          timer: 1000
        })
        await this.getEnabledCities()
      } else {
        await swal.fire({
          title: 'Erro!',
          text: 'Ocorreu um erro, tente novamente mais tarde.',
          denyButtonText: 'Ok',
          showDenyButton: true,
          showConfirmButton: false
        })
      }
    },
    getStateName (stateId) {
      return this.states.data.find(item => item.id === stateId)
    }
  },
  watch: {
    selectedState () {
      this.disabledCity = false
      this.getCitiesByState(this.selectedState)
    },
    states () {
      this.ready = true
    }
  }
}
</script>

<style>
.btnEnableCity {
  width: 100%;
  padding: 11px 0 !important;
  margin: 0 !important;
}
.myTag{
  padding: 5px 10px;
  background-color: #f0f9eb;
  color: #67c23a;
  border: 1px solid #e1f3d8;
  border-radius: 4px;
}
.myTag i{
  height: 17px;
  width: 17px;
  border-radius: 50%;
  color: #67c23a;
  cursor: pointer;
  background: transparent;
  transition: all linear 0.2s;
}
.myTag i:hover{
  background: #67c23a;
  color: #fff;
}
</style>
